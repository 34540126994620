<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Tryout CBT</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'cbt_tryout'}">Tryout CBT</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">

                                <div class="form-group col-md-6">                                    
                                    <label for="exampleFormControlSelect1">Kategori</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.cbt_category_id" :class="{ 'is-invalid': submitted && $v.form.cbt_category_id.$error }">
                                        <option value="">Pilih Kategori</option>
                                        <option v-for="(item, index) in datacategory" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.cbt_category_id.required" class="invalid-feedback">Kategori wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Judul Tryout</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Judul wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6 d-none">
                                    <label for="exampleFormControlSelect1">Tipe</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                        <option value="">Pilih Tipe</option>
                                        <option value="free">Free (Gratis)</option>
                                        <option value="premium">Premium (Berbayar)</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Tipe wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6 d-none" v-if="form.type == 'premium'">
                                    <label for="exampleInputEmail1">Harga Tryout</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.price">
                                </div>
                                <div class="form-group col-md-6 d-none" v-if="form.type == 'premium'">
                                    <label for="exampleInputEmail1">Harga Coret</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.price_out">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.status">
                                        <option value="publish">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Durasi Soal (Menit)</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan menit (Jika tidak disi, waktu default adalah 90 menit)" v-model="form.timer" :class="{ 'is-invalid': submitted && $v.form.timer.$error }">
                                    <div v-if="submitted && !$v.form.timer.required" class="invalid-feedback">Durasi wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Cover</label>
                                    <input type="file" class="form-control" name="cover" id="cover" @change="previewFiles" accept="cover/png, cover/gif, cover/jpeg">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_cover">
                                    <label for="exampleInputEmail1">Preview cover</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_cover" data-lightbox="1" target="blank">
                                                <img :src="preview_cover" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Waktu Mulai Tryout</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.start_date">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Waktu Selesai Tryout</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.end_date">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Tanggal Hasil Tryout</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.result_date">
                                </div>

                                <div class="form-group col-md-12" v-if="userdata.type == 'admin'">
                                    <label for="exampleInputEmail1">Sekolah</label>
                                    <multiselect
                                        @input="getSubjects"
                                        v-model="form.school_id" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih Sekolah" label="name" track-by="id" :options="list_school"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Tingkat</label>
                                    <multiselect
                                        v-model="form.grade" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih Tingkat" label="name" track-by="id" :options="list_grade"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Aktifkan Griya Belajar Exam (Aplikasi Ujian Griya Belajar)</label>
                                    <select class="form-control" v-model="form.safe_exam">
                                        <option value='1'>Iya</option>
                                        <option value='0'>Tidak</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6" v-if="userdata.type == 'admin'">
                                    <label for="exampleInputEmail1">Aktifkan Untuk Guru</label>
                                    <select class="form-control" v-model="form.access_teacher">
                                        <option value='1'>Iya</option>
                                        <option value='0'>Tidak</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Mata Pelajaran</label>
                                    <multiselect
                                        v-model="form.subjects_list" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih Mata Pelajaran" label="name" track-by="id" :options="dataSubjects"
                                        :multiple="true" :taggable="true"></multiselect>
                                    <small v-if="userdata.type == 'admin'">
                                        <i>Harap pilih sekolah untuk bisa menampilkan mata pelajaran.</i>
                                    </small>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">KKM (Batas Nilai)</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan KKM (Batas Nilai)" v-model="form.kkm" max="100">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Waktu Minimal Selesai Tryout (%)</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan Waktu Minimal Penyelesaian (contoh: 15%)" v-model="form.percentage_timer" max="100">
                                </div>
                            </div>

                            
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="alert alert-warning" role="alert">
                                        <b>Keterangan:</b><br/>
                                            - <i>Jika <b>GriyaBelajarExam diaktifkan</b>, maka tryout <b>hanya bisa di akses melalui aplikasi GriyaBelajarExam</b></i>.<br/>
                                            - <b>Waktu Minimal Selesai Tryout</b> adalah durasi minimum yang harus dicapai oleh peserta sebelum mereka diperbolehkan menyelesaikan atau mengakhiri tryout.
                                    </div>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'cbt_tryout'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import {
        mapState
    } from 'vuex'
    
    export default {
        name: 'Materi',
        metaInfo: {
            title: 'Form Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        mounted() {
            this.$store.dispatch('loadUserData');
        },
        computed: mapState(['userdata']),
        created() {
            this.getDataSchool();
            this.getcategory();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                preview_cover   : '',
                list_school     : [],
                list_grade: [
                    {
                        "id": "1",
                        "name": "1"
                    },
                    {
                        "id": "2",
                        "name": "2"
                    },
                    {
                        "id": "3",
                        "name": "3"
                    },
                    {
                        "id": "4",
                        "name": "4"
                    },
                    {
                        "id": "5",
                        "name": "5"
                    },
                    {
                        "id": "6",
                        "name": "6"
                    },
                    {
                        "id": "7",
                        "name": "7"
                    },
                    {
                        "id": "8",
                        "name": "8"
                    },
                    {
                        "id": "9",
                        "name": "9"
                    },
                    {
                        "id": "10",
                        "name": "10"
                    },
                    {
                        "id": "11",
                        "name": "11"
                    },
                    {
                        "id": "12",
                        "name": "12"
                    }
                ],
                datacategory   : [],
                dataSubjects : [],
                form : {
                    id              : "",
                    title           : "",
                    safe_exam       : 0,
                    type            : "free",
                    status          : "draft",
                    price           : 0,
                    price_out       : 0,
                    timer           : "",
                    cbt_category_id : "",
                    school_id       : [],
                    grade           : "",
                    cover           : "",
                    start_date : "",
                    end_date : "",
                    result_date : "",
                    access_teacher : 1,
                    subjects_id : '',
                    kkm : '',
                    percentage_timer : '',
                    subjects_list: []
                },
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                cbt_category_id: {
                    required
                },
                title: {
                    required
                },
                type: {
                    required
                },
                status: {
                    required
                },
                timer: {
                    required
                }
            }
        },
        methods: {
            getcategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/category/list';
                this.$http.get(uri).then(res => {
                    this.datacategory   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('title', this.form.title);
                    formData.append('type', this.form.type);
                    formData.append('status', this.form.status);
                    formData.append('cbt_category_id', this.form.cbt_category_id);
                    formData.append('price', this.form.price);
                    formData.append('price_out', this.form.price_out);
                    formData.append('timer', this.form.timer);
                    formData.append('school_id', JSON.stringify(this.form.school_id));
                    formData.append('grade', JSON.stringify(this.form.grade));
                    formData.append('subjects_list', JSON.stringify(this.form.subjects_list));
                    formData.append('cover', this.form.cover);
                    formData.append('safe_exam', this.form.safe_exam);
                    formData.append('access_teacher', this.form.access_teacher);
                    formData.append('kkm', this.form.kkm);
                    formData.append('percentage_timer', this.form.percentage_timer);

                    if(this.form.start_date) {
                        formData.append('start_date', this.form.start_date);
                    }

                    if(this.form.end_date) {
                        formData.append('end_date', this.form.end_date);
                    }

                    if(this.form.result_date) {
                        formData.append('result_date', this.form.result_date);
                    }

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/tryout/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'cbt_tryout' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data[0];
                            
                            this.form.id    = getResponse.id;
                            this.form.status    = getResponse.status;
                            this.form.title    = getResponse.title;
                            this.form.type    = getResponse.type;
                            this.form.cbt_category_id    = getResponse.cbt_category_id;
                            this.form.price    = getResponse.price;
                            this.form.price_out    = getResponse.price_out;
                            this.form.timer    = getResponse.timer;
                            this.form.school_id         = getResponse.list_school;                            
                            this.form.grade         = getResponse.list_grade;                            
                            this.form.cover     = getResponse.cover;
                            this.preview_cover  = getResponse.cover;
                            this.form.start_date   = getResponse.start_date;
                            this.form.end_date   = getResponse.end_date;
                            this.form.result_date   = getResponse.result_date;
                            this.form.safe_exam   = getResponse.safe_exam;
                            this.form.access_teacher   = getResponse.access_teacher;
                            this.form.subjects_id   = getResponse.subjects_id;
                            this.form.kkm                   = getResponse.kkm;
                            this.form.percentage_timer  = getResponse.percentage_timer;
                            this.form.subjects_list  = getResponse.subjects_list;
                            
                            this.getSubjects();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'cbt_tryout' });
                                }
                            });
                        }
                    });
                }
            },
            getDataSchool: function () {
                let uri = process.env.VUE_APP_APIHOST + 'admin/package/school';

                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.list_school = getResponse.data;

                        if(getResponse.type == 'content') {
                            this.list_school.forEach(element => {
                                if(getResponse.school_id == element.id) {
                                    this.form.school_id.push(element);
                                    this.getSubjects();
                                }
                            });
                        }
                    } else {
                        this.list_school = [];
                    }
                });
            },
            getSubjects: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/subjects-list';
                this.$http.get(uri, {
                    params : {
                        school_id : JSON.stringify(this.form.school_id)
                    }
                }).then(res => {
                    this.dataSubjects   = res.data.data;
                });
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_cover = URL.createObjectURL(file);
                    this.form.cover = file;
                }
            }
        }
    }
</script>