import { render, staticRenderFns } from "./testing_user_agent.vue?vue&type=template&id=02833545&"
import script from "./testing_user_agent.vue?vue&type=script&lang=js&"
export * from "./testing_user_agent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports