<template>
    <div>
        <div v-if="fetchOverview" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!fetchOverview" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:void(0)" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan Tryout CBT</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Tryout</div>
                                <div class="font-weight-600">{{dataTryout.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Waktu</div>
                                <div class="font-weight-600">{{ dataTryout.timer }} Menit</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Soal</div>
                                <div class="font-weight-600">{{ dataTryout.total_questions }} Soal</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Siswa Terdaftar</div>
                                <div class="font-weight-600">{{ dataTryout.total_members }} Orang</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Download Nilai</div>
                                <div class="font-weight-600">
                                    <span v-if="fetchDownload">Harap tunggu...</span>
                                    <button class="btn btn-primary rounded" type="button" v-if="!fetchDownload" v-on:click="downloadScore">
                                        Download Nilai
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Daftar Peserta</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <a href="javascript:void(0)" class="btn btn-primary rounded" data-toggle="modal" data-target="#modal_add_users"><i class="fa fa-plus"></i> Tambah Peserta</a>
                                <button v-if="selectedIds.length > 0" type="button" class="btn btn-danger rounded ml-2" v-on:click="deleteData()"><i class="fa fa-trash"></i> Hapus Peserta</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div v-if="dataTryout.user_type != 'content'" class="col-md-3">
                    <label for="exampleFormControlSelect1">Sekolah</label>
                    <select class="form-control" v-model="school_id" v-on:change="getData();changeClass()">
                        <option value="">Lihat Semua Sekolah</option>
                        <option v-for="(item_school, index_school) in dataSchool" :key="index_school" :value="item_school.id">{{ item_school.name }}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="exampleFormControlSelect1">Kelas</label>
                    <select class="form-control" v-model="school_class" v-on:change="getData">
                        <option value="">Lihat Semua Kelas</option>
                        <option v-for="(item_class, index_class) in dataClass" :key="index_class">{{ item_class }}</option>
                    </select>
                </div>
                <div :class="dataTryout.user_type != 'content' ? 'col-md-6':'col-md-9'">
                    <div class="form-group">
                        <label for="exampleFormControlSelect1">Kata kunci</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                    </div>
                </div>                
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div v-if="dataFetch">
                        <p>Sedang mengambil data...</p>
                    </div>

                    <!-- <div class="table-responsive" style="max-height: 750px; overflow-y: auto;"> -->
                    <div v-if="!dataFetch">
                        <div v-if="dataList.length > 0" class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            <input type="checkbox" v-model="selectAll" @change="selectAllItems">
                                        </th>
                                        <th style="width:5%" class="text-center">No</th>
                                        <th>Data Siswa</th>
                                        <th class="text-center">Benar</th>
                                        <th class="text-center">Salah</th>
                                        <th style="width:5%" class="text-center">Soal Esai</th>
                                        <th class="text-center">Skor Akhir</th>
                                        <th class="text-center">Waktu Mengerjakan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in dataList" :key="index">
                                        <td class="text-center">
                                            <input type="checkbox" v-model="selectedIds" :value="item.users_id">
                                        </td>
                                        <td class="text-center">
                                            {{ ++index }}
                                        </td>
                                        <td>
                                            {{ item.fullname }}<br/>
                                            <small>Email: <b>{{ item.email }}</b></small><br/>
                                            <small>Asal Sekolah: <b>{{ item.school_name }}</b></small><br/>
                                            <small>Kelas: <b>{{ item.school_class }}</b></small>
                                        </td>
                                        <td v-if="item.answer.length != '0'" class="text-center">
                                            {{ item.answer.total_correct }}
                                        </td>
                                        <td v-if="item.answer.length != '0'" class="text-center">
                                            {{ item.answer.total_wrong }}
                                        </td>
                                        <td v-if="item.answer.length != '0'" class="text-center">
                                            {{ item.answer.total_pending }}<br/>
                                            <small v-if="item.answer.total_pending > 0">(belum diberi penilaian)</small>
                                            <hr/>
                                            <button v-on:click="checkListAnswer(item.users_id, item.answer.id)" type="button" class="btn btn-sm btn-outline-primary rounded btn-sm" data-toggle="modal" data-target="#modal_periksa_jawaban"><i class="fa fa-edit"></i> Periksa Jawaban</button>
                                        </td>
                                        <td v-if="item.answer.length != '0'" class="text-center">
                                            {{ item.answer.skor_akhir }}
                                        </td>
                                        <td v-if="item.answer.length != '0'" class="text-center">
                                            {{ item.answer.start_date }}
                                        </td>
                                        <td v-if="item.answer.length == '0'" colspan="7" class="text-center">
                                            Belum mengerjakan TryOut
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="emptyState">
                            <empty-state></empty-state>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                
        <div style="margin-left:10px" class="modal" id="modal_periksa_jawaban" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-xlg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">Periksa Jawaban Siswa</div>
                            <div>
                                <button type="button" id="closeModalPeriksaJawaban" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body">
                        <p>
                            Nama Siswa: <b>{{ data_modal_users.fullname }}</b><br/>
                            Email: <b>{{ data_modal_users.email }}</b><br/>
                            Sekolah: <b>{{ data_modal_users.school_name }}</b><br/>
                            Kelas: <b>{{ data_modal_users.school_class }}</b>
                        </p>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width:5%" class="text-center">No</th>
                                        <th>Soal</th>
                                        <th>Jawaban Siswa</th>
                                        <th style="width:10%" class="text-center text-center">Status</th>
                                        <th style="width:10%" class="text-center text-center">Poin Diberikan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data_modal_answer" :key="index">
                                        <td class="text-center">{{ ++index }}</td>
                                        <td v-html="item.question"></td>
                                        <td v-if="!item.answer"><span class="text-danger"><b>Siswa tidak menjawab soal ini</b></span></td>
                                        <td v-if="item.answer" v-html="item.answer"></td>
                                        <td class="text-center">
                                            <span v-if="item.status == null" class="badge badge-secondary">Tidak Menjawab</span>
                                            <span v-if="item.status == 'pending'" class="badge badge-warning">Belum dinilai</span>
                                            <span v-if="item.status == 'wrong'" class="badge badge-danger">Salah</span>
                                            <span v-if="item.status == 'correct'" class="badge badge-success">Benar</span>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <input 
                                                    type="number" 
                                                    class="form-control" 
                                                    v-model.number="item.your_point" 
                                                    :max="item.point" 
                                                    :min=0
                                                    @input="validatePoint(item)" 
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary btn-sm" type="button" v-on:click="saveScore(item)"><i class="fa fa-save"></i></button>
                                                </div>
                                            </div>
                                            <small>Poin Maximal: {{ item.point }}</small>
                                            <br v-if="item.error"/>
                                            <small v-if="item.error" style="color: red;">{{ item.error }}</small>
                                        </td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-left:10px" class="modal" id="modal_add_users" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">Tambah Peserta</div>
                            <div>
                                <button type="button" id="btnCloseModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="alert alert-info text-center">Silahkan pilih sekolah terlebih dahulu untuk mendaftarkan peserta ke dalam tryout <b>{{dataTryout.title}}</b></div>
                        <select class="form-control mb-2" v-model="form_school_id" v-on:change="getUserAdd();changeClassForm()">
                            <option value="">Pilih Sekolah</option>
                            <option v-for="(item_school, index_school) in dataSchool" :key="index_school" :value="item_school.id">{{ item_school.name }}</option>
                        </select>

                        <select class="form-control mb-2" v-model="form_school_class" v-on:change="getUserAdd()">
                            <option value="">Pilih Kelas</option>
                            <option v-for="(item_class, index_class) in dataClassForm" :key="index_class">{{ item_class }}</option>
                        </select>

                        <input type="text" class="form-control mb-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama atau alamat email..." v-model="form_keywords" v-on:keyup.enter="getUserAdd">

                        <p v-if="dataFetchForm" class="text-center mt-4">Sedang mengambil data...</p>

                        <div v-if="!dataFetchForm">
                            <div v-if="form_school_id" class="table-responsive">
                                <table v-if="form_users_list.length > 0" class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-center">
                                                <input type="checkbox" v-model="formSelectAll" @change="formSelectAllItems">
                                            </th>
                                            <th style="width:5%" class="text-center">No</th>
                                            <th>Nama</th>
                                            <th>Email</th>
                                            <th>Kelas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item_users, index_users) in form_users_list" :key="index_users">
                                            <td class="text-center">
                                                <input type="checkbox" v-model="formSelectedIds" :value="item_users.id">
                                            </td>                                            
                                            <td class="text-center">
                                                {{ (index_users + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>{{ item_users.fullname }}</td>                            
                                            <td>{{ item_users.email }}</td>                            
                                            <td>{{ item_users.school_class ? item_users.school_class : '-' }}</td>                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="(form_school_id && form_users_list.length < 1)">
                                <empty-state></empty-state>
                            </div>

                            <div v-if="form_users_list.length > 0" class="text-center mt-3">
                                <nav aria-label="navigation">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getUserAdd()"></v-pagination>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer" v-if="formSelectedIds.length > 0">
                        <button v-on:click="assignUser()" type="button" class="btn btn-primary rounded" id="saveButton"><i class="fa fa-save"></i> Tambahkan Peserta</button>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        name: 'AdminCBTMonitoring',
        metaInfo: {
            title: 'Monitoring Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                fetchOverview : false,
                dataFetch : false,
                dataTryout : [],
                dataList : [],
                emptyState : false,
                keywords : '',
                fetchDownload : false,
                fetch_modal_answer : false,
                data_modal_users   : [],
                data_modal_answer : [],
                id_answer : "",
                school_id : '',
                school_class : '',
                dataSchool : [],
                dataClass  : [],
                dataClassForm  : [],
                selectAll   : false,
                selectedIds : [],
                form_keywords : '',
                form_school_id : '',
                form_school_class : '',
                form_users_list : [],
                dataFetchForm : false,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                formSelectAll   : false,
                formSelectedIds : [],
            }
        },
        created() {
            this.getSchool();
            this.getOverview();
        },
        methods: {
            changeClass:function() {
                this.dataClass = [];
                this.school_class = "";
                this.dataSchool.forEach(element => {
                    if(element.id == this.school_id) {
                        this.dataClass = element.data_class;
                    }
                });
            },
            changeClassForm:function() {
                this.dataClassForm = [];
                this.form_school_class = "";
                this.dataSchool.forEach(element => {
                    if(element.id == this.form_school_id) {
                        this.dataClassForm = element.data_class;
                    }
                });
            },
            getUserAdd: function() {
                if(this.form_school_id) {
                    let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout-members/not-assign';

                    this.dataFetchForm  = true;
                    this.$http.get(uri,{
                        params : {
                            keywords      : this.form_keywords,
                            school_id     : this.form_school_id,
                            school_class  : this.form_school_class,
                            page          : this.pagination.currentPage,
                            limit         : this.pagination.limit,
                            cbt_tryout_id : this.$route.params.id
                        }
                    }).then(res => {
                        this.dataFetchForm = false
                        this.form_users_list        = res.data.data;
                        this.pagination.totalPages  = res.data.total_page;
                    });
                } else {
                    this.dataFetchForm   = false
                    this.form_users_list = [];
                }
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            validatePoint(item) {
                if (item.your_point > item.point) {
                    item.error = `Poin tidak boleh lebih dari ${item.point}`;
                } else {
                    item.error = '';
                }
            },
            saveScore: function(item) {
                if(item.your_point !== null && item.your_point !== '') {                    
                    if(item.your_point <= item.point) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/tryout/save-essay-score', {
                            your_point   : item.your_point,
                            id           : item.id,
                            cbt_tryout_id : item.cbt_tryout_id,
                            cbt_tryout_answer_id : this.id_answer,
                            cbt_tryout_question_id : item.cbt_tryout_question_id,
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                        this.checkListAnswer(item.users_id, item.id);
                                    }
                                });
                            }
                        })                        
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap masukan poin dengan benar!",
                    });
                }
            },
            getOverview: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/overview';
                
                this.fetchOverview  = true;                
                this.$http.get(uri, {
                    params : {
                        id : this.$route.params.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.fetchOverview  = false;
                        this.dataTryout     = res.data.data;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'cbt_tryout'
                                });
                            }
                        });                        
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/monitoring-users';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id           : this.$route.params.id,
                        keywords     : this.keywords,
                        school_id    : this.school_id,
                        school_class : this.school_class
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }

                    if(this.dataTryout.user_type == 'content') {
                        this.school_id = this.dataTryout.user_school;
                        this.changeClass();
                    }
                });
            },
            downloadScore: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/monitoring-users-excel';
                
                this.fetchDownload  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                        keywords    : this.keywords
                    },
                    responseType: 'blob'
                }).then(response => {
                    this.fetchDownload = false
                    
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'HASIL CBT - '+this.dataTryout.title}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                    }
                });                
            },
            selectAllItems() {
                if (this.selectAll) {
                    this.selectedIds = this.dataList.map(item => item.users_id);
                } else {
                    this.selectedIds = [];
                }

                console.log(this.selectedIds);
            },
            formSelectAllItems() {
                if (this.formSelectAll) {
                    this.formSelectedIds = this.form_users_list.map(item => item.id);
                } else {
                    this.formSelectedIds = [];
                }
            },
            checkListAnswer: function(users_id=null, id_answer=null) {
                this.id_answer = "";
                this.dataList.forEach(element => {
                    if(element.users_id == users_id) {
                        this.id_answer = element.answer.id;
                        this.data_modal_users = element;
                    }
                });

                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/monitoring-users-detail';
                this.$http.get(uri,{
                    params : {
                        cbt_tryout_id          : this.$route.params.id,
                        cbt_tryout_answer_id   : id_answer
                    }
                }).then(res => {
                    this.data_modal_answer = res.data.data;
                });                
            },
            assignUser: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Tambahkan Peserta',
                    html: 'Apakah kamu yakin ingin <b>menambahkan '+this.formSelectedIds.length+'</b> data peserta ke <b>'+this.dataTryout.title+'</b>?',
                    confirmButtonText: 'Iya, tambahkan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/assign-member-to-tryout', {
                        id: this.formSelectedIds,
                        cbt_tryout_id: this.$route.params.id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                    this.formSelectedIds = [];
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });                
            },
            deleteData: function() {
                this.$swal({
                    icon: 'Perhatian',
                    title: 'Hapus Peserta',
                    html: 'Apakah kamu yakin ingin <b>menghapus '+this.selectedIds.length+'</b> data peserta dari <b>'+this.dataTryout.title+'</b>?',
                    confirmButtonText: 'Iya, Hapus Peserta',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/delete-member-assign-tryout', {
                        id: this.selectedIds,
                        cbt_tryout_id: this.$route.params.id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                    this.selectedIds = [];
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });                
            },
        }
    }
</script>